import ApiService from "@/core/services/api.service.js";

const lensPackageService = {
      getGlassUsages() {
          return  new Promise((resolve, reject) => {
            ApiService.get("api/admin/glassusages")
              .then(function(response) {
                if (response.data) {
                  resolve(response.data);
                } else {
                  resolve("");
                }
              })
              .catch(function(error) {
                reject(error)
              });
          });
      },
      getLensPriceGroups() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/lensePriceGroup")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getLensPriceGroupById(id,usageId) {
      return  new Promise((resolve, reject) => {
        ApiService.get("api/admin/lensePriceGroup/detail/"+id+"/"+usageId)
          .then(function(response) {
            if (response.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
    },
    getLensPackages() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/lensePackage")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getLensTypes() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/lenseType")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createPackage(data,usageId) {
      return  new Promise((resolve, reject) => {
        ApiService.post("api/admin/lensePriceGroup/create/"+usageId,data)
          .then(function(response) {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
    },
    updatePackage(id,data) {
        return  new Promise((resolve, reject) => {
          ApiService.put("api/admin/lensePriceGroup/update/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deletePriceGroups(id) {
      return  new Promise((resolve, reject) => {
        ApiService.delete("api/admin/lensePriceGroup/delete/"+id)
          .then(function(response) {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },
}

export default lensPackageService;